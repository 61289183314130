import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero/geschaeftskunden"
import imgTitleGeschaeftskunden from "../images/page_title_geschaeftskunden.png"
import imgTitleRoestereiPeine from "../images/page_title_roesterei-peine.png"

const Geschaeftskunden = props => {
  return (
    <Layout>
      <Seo title="unsere kaffeerösterei in peine" />
      <Hero />
      <section className="geschaeftskunden container">
        <img
          className="page-title"
          src={imgTitleGeschaeftskunden}
          alt="kaffeerösterei in peine"
        />
        <p className="content-center">
          <strong>
            wir bieten individuelle kaffeelösungen für gastronomie, bäckereien,
            hotellerie und büros:
          </strong>
          <ul>
            <li>frischen handwerklich hergestellten röstkaffee</li>
            <li>verkauf und installation von kaffee- und espressomaschinen</li>
            <li>wartung, service, einstellung ihres equipments</li>
            <li>lieferung von kaffee und zusatzprodukten frei haus</li>
            <li>individualität, qualität, nachhaltigkeit und flexibilität</li>
          </ul>
        </p>

        <p className="content-center">
          <strong>wir freuen uns auf ihre nachricht</strong> <br />
          heinrich-hertz-straße 7 · 31228 peine
          <br />
          telefon 05171 508370
          <br />
          info@daskleineroestwerk.de
        </p>
        <img
          className="page-title"
          src={imgTitleRoestereiPeine}
          alt="kaffeerösterei in peine"
        />
        <p>
          hier liegt der ursprung unserer kaffeerösterei. sie können alle unsere
          kaffees telefonisch, per mail oder online für peine vorbestellen und
          hier abholen
        </p>
        <p className="content-center">
          <strong>kaffee nach vorbestellung in peine</strong> 
          <br />
          telefon 05171 508370
          <br />
          mo.-fr. 8-12 uhr
        </p>
        <div className="page-imagebox">
          <div className="page-imagebox-image">
            <GatsbyImage
              image={props.data.page_image1.childImageSharp.gatsbyImageData}
              alt="kaffee aus hildesheim" />
          </div>
          <div className="page-imagebox-image">
            <GatsbyImage
              image={props.data.page_image2.childImageSharp.gatsbyImageData}
              alt="kaffeerösterei hildesheim" />
          </div>
          <div className="page-imagebox-image">
            <GatsbyImage
              image={props.data.page_image3.childImageSharp.gatsbyImageData}
              alt="zuckerhut hildesheim" />
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`{
  page_image1: file(relativePath: {eq: "page_geschaeftskunden-1.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  page_image2: file(relativePath: {eq: "page_geschaeftskunden-2.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  page_image3: file(relativePath: {eq: "page_geschaeftskunden-3.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`

export default Geschaeftskunden
