import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import "./_hero.scss"

const Hero = () => {
  const data = useStaticQuery(graphql`{
  hero: file(relativePath: {eq: "hero_geschaeftskunden.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`)

  return (
    <div className="geschaeftskunden">
      <div className="hero">        
        <GatsbyImage
          image={data.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="kaffeelösungen für gastronomie, bäckereien, hotellerie und büros" />
      </div>
    </div>
  );
}
export default Hero
